import React from "react";
import SettingsPageController, { Props } from "./SettingsPageController.web";
import CustomLayout from "../../../components/src/util/CustomLayout.web";
import { Typography, Box, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Divider from "@material-ui/core/Divider";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledRadio from "../../../components/src/util/CustomRadio.web";
import CloseIcon from "@material-ui/icons/Close";
import BusinessUnitModal from "../../../components/src/util/BusinessUnitModal.web";
import TagsModal from "../../../components/src/util/TagsModal.web";
class SettingsPage extends SettingsPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      businessExpandOpen,
      selectedBusinessUnit,
      createBusinessUnitModal,
      editBusinessUnitModal,
      tagsExpand,
      editTagsModal,
      createTagsModal,
      businessUnitList,
      tagList,
    } = this.state;
    return (
      <CustomLayout title="Settings" backButton={true} goBack={this.goBack}>
        <Box sx={{ p: 6, maxWidth: "900px", margin: "0 auto" }}>
          <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <Typography
              component="p"
              variant="h6"
              style={{
                color: "#666666",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Business Unit
            </Typography>
            <IconButton
              data-testid="createBusinessUnitBtn"
              onClick={() => this.handleCreateBusinessModal(true)}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ bgcolor: "white", borderRadius: "7px", px: 3 }}>
            <List>
              <ListItem
                data-testid="businessExpandButton"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
                button
                onClick={this.handleBuisnessExpand}
              >
                <ListItemText style={{ fontWeight: 600 }} primary="Select" />
                {businessExpandOpen ? (
                  <ExpandLess data-testid="businessExpandLessButton" />
                ) : (
                  <ExpandMore data-testid="businessExpandMoreButton" />
                )}
              </ListItem>
              {businessExpandOpen && (
                <Divider
                  style={{ marginBottom: "7px" }}
                  data-testid="businessExpandDevider"
                />
              )}
              <Collapse in={businessExpandOpen} timeout="auto" unmountOnExit>
                <RadioGroup
                  data-testid="businessRadioGroup"
                  value={selectedBusinessUnit?.id}
                  onChange={this.handleChangeBusinessUnit}
                >
                  {businessUnitList.map((item) => (
                    <FormControlLabel
                      data-testid="businessRadioItem"
                      key={item.id}
                      value={item.id}
                      control={<StyledRadio />}
                      label={item.attributes?.name}
                    />
                  ))}
                </RadioGroup>
                {selectedBusinessUnit?.id && (
                  <Box
                    data-testid="businessActionContainers"
                    sx={{
                      mt: 2,
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      data-testid="editBusinessButton"
                      onClick={() => this.handleEditBusinessModal(true)}
                      style={{
                        background: "#7BD649",
                        width: "28px",
                        height: "28px",
                      }}
                    >
                      <EditIcon fontSize="small" style={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      onClick={this.handleBuisnessExpand}
                      style={{
                        background: "#EA4848",
                        marginLeft: "4px",
                        width: "28px",
                        height: "28px",
                      }}
                    >
                      <CloseIcon
                        fontSize="small"
                        style={{ color: "white", fontSize: "16px" }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Collapse>
            </List>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <Typography
              component="p"
              variant="h6"
              style={{
                color: "#666666",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Tags
            </Typography>
            <IconButton
              data-testid="createTagButton"
              onClick={() => this.handleCreateTagsModal(true)}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ bgcolor: "white", borderRadius: "7px", px: 3 }}>
            <List>
              <ListItem
                data-testid="tagExpandButton"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
                button
                onClick={this.handleTagExpand}
              >
                <ListItemText style={{ fontWeight: 600 }} primary="Select" />
                {tagsExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {tagsExpand && <Divider style={{ marginBottom: "7px" }} />}
              <Collapse in={tagsExpand} timeout="auto" unmountOnExit>
                {tagList.map((item, index) => (
                  <Box
                    data-testid="tagListContainer"
                    key={item.id}
                    style={{
                      background: "#F5F6F7",
                      borderRadius: "8px",
                      padding: "8px 12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        data-testid="tagColor"
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "50%",
                          background: item?.attributes?.color
                            ? item?.attributes?.color
                            : "#e46e2a",
                        }}
                      />
                      <Typography
                        data-testid="tagName"
                        component="h2"
                        variant="h6"
                        style={{ fontSize: "18px" }}
                      >
                        {item?.attributes?.name}
                      </Typography>
                    </div>
                    <div>
                      <IconButton
                        data-testid="editTagsButton"
                        onClick={() => this.handleSelectTag(item)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Box>
                ))}
              </Collapse>
            </List>
          </Box>
        </Box>

        {createBusinessUnitModal && (
          <BusinessUnitModal
            data-testid="createBusinessUnitModal"
            open={createBusinessUnitModal}
            setOpen={this.handleCreateBusinessModal}
            edit={false}
            actionLoading={this.state.createBusinessUnitLoading}
            actionHandler={this.createNewBusinessUnit}
          />
        )}
        {editBusinessUnitModal && (
          <BusinessUnitModal
            data-testid="editBusinessUnitModal"
            open={editBusinessUnitModal}
            setOpen={this.handleEditBusinessModal}
            edit={true}
            actionLoading={this.state.editBusinessUnitLoading}
            actionHandler={this.editBusinessUnit}
            businessUnit={this.state.selectedBusinessUnit?.attributes?.name}
          />
        )}
        {createTagsModal && (
          <TagsModal
            data-testid="createTagsModal"
            open={createTagsModal}
            setOpen={this.handleCreateTagsModal}
            edit={false}
            actionLoading={this.state.createTagLoading}
            actionHandler={this.createNewTag}
          />
        )}
        {editTagsModal && (
          <TagsModal
            data-testid="editTagsModal"
            open={editTagsModal}
            setOpen={this.handleEditTagsModal}
            edit={true}
            actionLoading={this.state.editTagLoading}
            actionHandler={this.editTag}
            name={this.state.selectedTag?.attributes?.name}
            color={this.state.selectedTag?.attributes?.color}
          />
        )}
      </CustomLayout>
    );
  }
}

export default SettingsPage;

const webStyle = {
  container: {
    height: "100vh",
    overflow: "hidden",
    background: "#F2F2F2",
  },
  sidebarButton: {
    background: "transparent",
    borderRadius: "0px",
  },
  leftSideBarContainer: {},
};
