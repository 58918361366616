import { LogLevel } from '@azure/msal-browser';
export const msalConfig = {
  auth: {
    clientId: '13f33a89-d594-4ee6-8032-a4c5f1a58c07',
    authority:
      'https://login.microsoftonline.com/38a47423-5b86-4ded-a0b3-f3053100d5e6',
    redirectUri:
      'https://ideaplatformws2022-290997-react.b290997.dev.eastus.az.svc.builder.cafe/'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};
export const loginRequest = {
  scopes: ['User.Read']
};
