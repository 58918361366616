import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      textTransform: "capitalize",
      borderRadius: "15px",
      boxShadow: "none",
      minWidth: "120px",
    },
    ashButton: {
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
      minWidth: "120px",
      "&:hover": {
        boxShadow: "none",
      },
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      justifyContent: "end",
    },
    colorInput: {
      border: "none",
      width: "100%",
      height: "60px",
      borderRadius: "10px",
      marginLeft: "5px",
    },
  })
);

interface Props {
  edit: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  actionLoading?: boolean;
  actionHandler?:any;
  color?:string;
  name?:string;
}

export default function TagsModal({
  open,
  setOpen,
  edit,
  actionLoading,
  actionHandler,
  color,
  name,
}: Props) {
  const styles = useStyles();
  const [tagName, setTagName] = React.useState<string>(edit?name as string:"");
  const [tagColor, setTagColor] = React.useState<string>(edit && color?color:"");
  const [errors,setErrors]=React.useState<any>({});

 const handleSubmit=()=>{
   const newError:any={};
   if(!tagName){
    newError.tagName="Please Enter Tag Title";
   }
   if(!tagColor){
    newError.tagColor="Please Enter Tag Color"
   }
   if(!tagName || !tagColor){
      setErrors(newError)
   }else{
     setErrors({})
     const body = {
       tag: {
         name: tagName,
         color: tagColor
       }
     };
      actionHandler(body);
   }
 }

  const getButtonText = () => {
    if (edit) {
      return "Update";
    } else {
      return "Save";
    }
  };

  return (
    <Modal
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper
        style={{
          width: "680px",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h5" style={{ fontWeight: 600, fontSize: "22px" }}>
          {edit ? "Update Tag" : "Create Tag"}
        </Typography>

        <Box sx={{ py: 3 }}>
          <TextField
            fullWidth
            label="Tag Title"
            variant="outlined"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            style={{ marginBottom: "10px" }}
            error={errors?.tagName}
            helperText={errors?.tagName}
          />
          <br />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ flex: "8" }}>
              <TextField
                fullWidth
                label="Tag Color"
                variant="outlined"
                value={tagColor}
                onChange={(e) => setTagColor(e.target.value)}
                error={errors?.tagColor}
                helperText={errors?.tagColor}
              />
            </Box>
            <Box sx={{ flex: "1" }}>
              <input
                className={styles.colorInput}
                type="color"
                value={tagColor}
                onChange={(e) => setTagColor(e.target.value)}
              />
            </Box>
          </Box>
        </Box>

        <Box className={styles.buttonContainer}>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            className={styles.ashButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={actionLoading}
            variant="contained"
            color="primary"
            className={styles.submitButton}
          >
            {actionLoading ? <CircularProgress size={24} /> : getButtonText()}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
