import { Card, Box, Typography, IconButton } from "@material-ui/core";
import { logoImg } from "../../../blocks/customform/src/assets";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  logo?: boolean;
  title: string;
  optionButton?: boolean;
  titleStyle?: any;
  left?: string;
  positionHeader?: boolean;
}

const AppHeader = ({
  logo,
  title,
  optionButton,
  titleStyle,
  left = "0",
  positionHeader,
}: Props) => {
  const isAdmin = localStorage.getItem("isAdmin") === "true" ? true : false;

  return (
    <Card
      style={{
        background: "#F2F2F2",
        borderBottom: "1px solid #E4E4E4",
        display: "flex",
        alignItems: "center",
        gap: "20px",
        justifyContent: "space-between",
        padding: "24px 12px",
        position: positionHeader ? "relative" : "fixed",
        left: left,
        right: "0",
        zIndex: 100,
        borderRadius: "0px",
      }}
      elevation={0}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {logo && (
          <Link to={isAdmin ? "/AdminLandingPage" : "/ApplicantLandingPage"}>
            <img
              style={{
                width: "162px",
                height: "40px",
                objectFit: "cover",
              }}
              src={logoImg}
              alt="logo"
            />
          </Link>
        )}

        <Typography
          component="h1"
          variant="h3"
          style={{
            ...titleStyle,
            fontSize: "32px",
            fontWeight: "600",
            color: "black",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Card>
  );
};

export default AppHeader;
