import React, { CSSProperties, useCallback, useRef } from 'react'

import {
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    CardActions,
  } from "@material-ui/core";
import { localeData } from 'moment';

import {
  CardImage
} from "./assets";  

const ProjectCard = ({ProjectData,navigateProduct,loadMore,loading}:any) => {
  const observer = useRef<any>(null)
  const lastBookElementRef = useCallback((node:any) => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    })
    if (node) observer.current.observe(node)
  }, [loading,ProjectData])

 
  return (
    <Grid container spacing={3} style={webStyle.browseCards}>
      {
         ProjectData?.map((Pdata:any,index:any)=>
    <Grid item xs={4} style={webStyle.cardWidth as CSSProperties} ref={ProjectData?.length === index + 1?lastBookElementRef:null}>
    <Paper style={webStyle.cardBody as CSSProperties}>
      <Card style={webStyle.cardBody as CSSProperties}>
        <CardActionArea>
          <CardMedia>
            <img src={Pdata?.attributes?.cover_image?.url}
              style={webStyle.cardImg as CSSProperties}
              onError={(e:React.SyntheticEvent<HTMLImageElement, Event>)=>{
                e.currentTarget.onerror=null;
                e.currentTarget.src=CardImage;
              }}
              />
          </CardMedia>
          <CardContent >
            <Typography gutterBottom variant="h5" component="h2" style={webStyle.cardHead as CSSProperties}>
              {Pdata?.attributes?.idea_title !== "undefined" ? Pdata?.attributes?.idea_title : ''}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={webStyle.cardPera as CSSProperties}>
            {Pdata?.attributes?.idea_description !== "undefined" ? Pdata?.attributes?.idea_description : ''}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button variant="contained" style={webStyle.cardBtnOrange as CSSProperties} onClick={()=>navigateProduct(Pdata?.id)}>View Project</Button>          
        </CardActions>
      </Card>
    </Paper>
  </Grid>
  
  )
}
  </Grid>
  )
}

const webStyle = {
    cardHead: {
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: '500',
      color: '#171717',
      marginBottom: '8px',
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      height: "25px",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      msTextOverflow: "ellipsis",                  
    },
    cardPera: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#999',
      height: "46px",
      lineHeight: '23px',
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      msTextOverflow: "ellipsis",  
    },
    cardBtnOrange: {
      background: '#E46E2A',
      height: '32px',
      color: '#fff',
      textTransform: 'capitalize',
      fontWeight: '500',
      padding: ' 9px 25px',
      fontSize: '12px',
      borderRadius: '8px',
      minWidth: '100%'
    },
    browseCards: {
      marginTop: '20px',
    },
    cardImg:{
      width:'100%',
      height:'158px',
      objectFit:'cover',
    },
    cardBody:{
      boxShadow: "unset",
      borderRadius: '10px',
    },
    cardWidth:{
      maxWidth: '31%',
    }
  };
  
export default ProjectCard