import React, {CSSProperties} from "react";

import {
  Box,
  Button,
  InputAdornment,
  // Customizable Area Start
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TextField,
  // Customizable Area End  
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import ProjectTable from "../../../components/src/ProjectTableContainer";
import SearchIcon from '@material-ui/icons/Search';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController.web";

export default class AdminLandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
      // Customizable Area Start
    // Customizable Area End    
  }
   // Customizable Area Start
  // Customizable Area End

  render() {
    return (
    // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.AdminMainWrapper}>
        <SideBar taglist={this.state.tagList} clickOnLogo={()=>{this.handleOnRedirectToLandingPage()}} />          
          <Box sx={webStyle.AdminWrapper}>
            <AppHeader heading={'Projects'}/>   
            <Box sx={webStyle.AdminContentCentre}>
              <Box  sx={webStyle.AdminSearchBox}>
                <form autoComplete="off" style={webStyle.AdminFormInput}>
                  <TextField id="outlined-basic" placeholder="search" variant="outlined" style={webStyle.AdminInputField} 
                  onChange={(event)=>this.handleOnSearch(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={webStyle.AdminInputAdornment}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }} />
                </form>
                <Button variant="contained" style={webStyle.AdminBtnOrange as CSSProperties} onClick={()=>{this.handleonClickToNewProject()}}>+ New Idea</Button>
              </Box>
              <Box sx={webStyle.AdminTableSample}>
                <TableContainer component={Paper} style={webStyle.AdminTableContainer as CSSProperties}>
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead style={webStyle.AdminTableHead as CSSProperties}>
                      <TableRow>
                        <TableCell align="left" style={webStyle.AdminPaddingReduce as CSSProperties}>Project Title</TableCell>
                        <TableCell align="left">Date Submitted</TableCell>
                        <TableCell align="left">Bussiness Unit</TableCell> 
                        <TableCell align="left">Applicant</TableCell>
                        <TableCell align="right" style={{paddingRight: "30px"}}>Status</TableCell>
                      </TableRow>
                    </TableHead>                   
                      <ProjectTable                     
                      newProjectApplications={this.state.newProjectApplications}
                      loadMore={this.getNewProjectApplications}
                      loading={this.state.loading}
                      navigateToProjectDetails={this.showProjectDetail}
                    />
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  AdminMainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    background: "#fff",
    overflowX: 'hidden',
    height:'100vh',
  },  
  AdminPaddingReduce: {
    paddingInline: "0px",
    paddingLeft:"20px",
  },
  AdminWrapper: {
    background: '#F2F2F2',
    width: 'calc(100% - 312px)',
    fontSize:'14px',
    marginLeft:'312px',
    height: '100%',
    overflowY:"hidden"
  },
  AdminContentCentre: {
    padding:'24px 32px',
  },
  AdminSearchBox: {
    display:'flex',
    justifyContent:'space-between',
    marginBottom:'15px',
    '& fieldset': {
      boxShadow: '0px 6px 17px rgba(228, 110, 42, 0.05)',
      borderColor: 'transparent !important',
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
    },
  },
  AdminInputField: {
    background:'#fff',
    minWidth:'450px',
    borderRadius:'20px'
  },
  AdminBtnOrange: {
    background:'#E46E2A',
    height: '45px',
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: '500',
    padding:' 10px 25px',
    fontSize: '14px',
    minWidth: '150px',
  },
  AdminFormInput: {
    marginBottom:'0px'
  },
  AdminTableSample: {
    boxShadow: '0px 6px 17px rgba(228, 110, 42, 0.05)',
    borderRadius: '24px',
    background:'#fff',
    padding:'0 24px',
    paddingBottom: "20px",
    overflowY: 'auto',
    minHeight: '0px',
    maxHeight: '75vh',
    overflowX: 'hidden',
  },
  AdminTableContainer:{
    overflowX: 'initial',
    boxShadow: 'unset',
  },
  AdminTableHead:{
    position: 'sticky',
    tableLayout: 'fixed',
    overflow: 'auto',
    top: '0',
    backgroundColor:'#FFFFFF',
    zIndex:1,
  },
  AdminBtnPro: {
    backgroundColor:'rgba(228, 110, 42, 0.2)',
    color: '#E46E2A',
    textTransform: 'capitalize',
    fontWeight: '500',
    padding:'0px 1px',
    fontSize: '14px',
    border:'1px solid #E46E2A',
  },
   AdminInputAdornment:{
    opacity: 0.5,
  },
  titleHead:{
    paddingLeft: '66px',
  }
};
 // Customizable Area End
