import React,{CSSProperties, useRef,useCallback} from 'react'
import {TableBody,TableRow,TableCell,Button,Box} from "@material-ui/core"
import { img1 } from '../../blocks/landingpage/src/assets';

const webStyle = {
  AdminFlexBox: {
    display:'flex',
    alignItems:'center',
    fontSize:'16px',
  },
  AdminImgUser: {
    width:'32px',
    height:'32px',
    borderRadius:'50%',
    marginRight:'17px',
  }, 
  AdminTableBody: {
    color:'#171717',
    overflow:"scroll"
  },
  AdminBtnPro: {
    backgroundColor:'rgba(228, 110, 42, 0.2)',
    color: '#E46E2A',
    textTransform: 'capitalize',
    fontWeight: '500',
    padding:'0px 5px 1px',
    fontSize: '14px',
    border:'1px solid #E46E2A',
  },
  AdminPaddingReduce: {
    paddingInline: "0px"
  },
  cardPera: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    whiteSpace: "norrgb(243, 243, 243)mal",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    msTextOverflow: "ellipsis", 
    width:"280px" 
  },
};
interface Props{
    newProjectApplications:any[];
    loadMore:()=>void;
    loading:boolean;
    navigateToProjectDetails:(id:any)=>void;
}
const ProjectTable=({newProjectApplications,loadMore,loading,navigateToProjectDetails}:Props)=>{
 const observer = useRef<any>(null)
  const lastBookElementRef = useCallback((node:any) => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
        console.log("Entries",entries)
      if (entries[0].isIntersecting) {
        loadMore();
      }
    })
    if (node) observer.current.observe(node)
  }, [loading,newProjectApplications])

    console.log(newProjectApplications,"neProjectApplications");
    return(
        <TableBody style={webStyle.AdminTableBody as CSSProperties}>
                    {newProjectApplications.map((data,index)=>
                    <TableRow key={data?.id} ref={newProjectApplications?.length === index + 1? lastBookElementRef:null}>      
                        {/*@ts-ignore */}
                        <TableCell component="th" scope="row" style={webStyle.AdminPaddingReduce as CSSProperties}>
                          <Box sx={webStyle.AdminFlexBox}>
                        <img src={data?.attributes?.cover_image?.url} alt={img1} style={webStyle.AdminImgUser} /><b style={webStyle.cardPera as CSSProperties}>{data?.attributes?.idea_title}</b></Box>
                        </TableCell>
                        <TableCell align="left"><b  style={webStyle.cardPera as CSSProperties}>{data?.attributes?.submitted_date}</b></TableCell>
                        <TableCell align="left"><b  style={webStyle.cardPera as CSSProperties}>{data?.attributes?.business_unit?.name}</b></TableCell>
                        <TableCell align="left"><b  style={webStyle.cardPera as CSSProperties}>{data?.attributes?.idea_owner_name}</b></TableCell>
                        <TableCell align="right" style={webStyle.AdminPaddingReduce as CSSProperties}><Button variant="contained" style={webStyle.AdminBtnPro as CSSProperties} onClick={()=>{navigateToProjectDetails(data.id)}}> Open project </Button></TableCell>
                      </TableRow>
                      )}
                    </TableBody>
    )
}

export default ProjectTable;
