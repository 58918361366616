import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";

import { MsalContext } from "@azure/msal-react";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  title: string;
  submittedDate: string;
  businessUnit: string;
  ideaOwnerName: string;
  tags: string[];
  email: string;
  description: string;
  file: any;
  errors: any;
  similarProjects: string[]; 
  fileName: string;
  fileSize: any;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProjectDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  static contextType = MsalContext;
  projectDetailCallId: string = "";
  isAdmin: boolean = false;
  ref: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
     this.ref = React.createRef()

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      title: "",
      submittedDate: "",
      businessUnit: "",
      ideaOwnerName: "",
      tags: [],
      email: "",
      description: "",
      file: null,
      errors: {},
      similarProjects: [],
      fileName: '',
      fileSize: "",
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiRequestCallId === this.projectDetailCallId) {
        const projectData = responseJson?.data?.attributes
        this.setState({
          title: projectData?.idea_title,
          submittedDate: projectData?.submitted_date,
          businessUnit: projectData?.business_unit?.name,
          ideaOwnerName: projectData?.idea_owner_name,
          tags: projectData?.tags,
          description: projectData?.idea_description,
          similarProjects: projectData?.similar_projects?.data,
          fileName: projectData?.document?.filename,
          fileSize: projectData?.document?.filesize,
          file: projectData?.document?.url,
        })
      }

      
    }
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    const isUserAuthenticated = this.context?.accounts.length > 0;
    if (!isUserAuthenticated) {
      this.navigateToLoginPage();
    }
    const isAdmin =localStorage?.getItem("isAdmin")==="true"?true:false;  
    this.isAdmin = isAdmin;
    const projectId = this.props.navigation.getParam('id')
    this.getProjectDetail(projectId)
    // Customizable Area End
  }



  navigateToEditProject = () => {
    const projectId = this.props.navigation.getParam('id')
    this.props.navigation.navigate('EditProject', {projectId})
  }

  getProjectDetail = (projectId: any) => {
    this.ref?.current?.scroll({
      top: 0,
      left:0,
      behavior: "smooth"
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getProjectDetailApiURL}/${projectId}`,
      body: null,
      method: configJSON.getProjectDetailMethodType,
    });
    this.projectDetailCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  navigateToLoginPage = () => {
    this.props.navigation.navigate("Login");
  };
  // Customizable Area End
}
