import React from "react";
import { Box, Typography } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
interface Props {
  attatchment: any;
  changeHandler: any;
  removeHandler: () => void;
  error: string;
}
const SelectAttatchment = ({
  attatchment,
  changeHandler,
  removeHandler,
  error,
}: Props) => {


const handleDownload = () => {
    if(attatchment !==null && attatchment?.filename){
      window.open(attatchment?.url)
    } 
  };


  let attatchmentName="";
  if(attatchment?.filename && attatchment?.url){
    attatchmentName=attatchment?.filename;
  }else if(attatchment?.name){
    attatchmentName=attatchment?.name
  }


  return (
    <>
     <Typography 
          style={{ color: "#666666",
            fontSize: "16px",
            fontWeight: 600}}
        >File Attachments</Typography>
          <Box
            style={{
              marginTop: "10px",
              border: "2px dashed #E4E4E4",
              width: "100%",
              padding: "15px",
              background: "white",
              borderRadius: "8px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {attatchment === null ? (
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                  gap: "10px",
                }}
                htmlFor="fileInput"
              >
                <input
                  data-testid="attatchmentInput"
                  onChange={changeHandler}
                  id="fileInput"
                  accept=".pdf, .doc, .xls, .xlsx, .docx ,.odf ,.ppt"
                  type="file"
                  style={{ visibility: "hidden" }}
                />
                <label
                  htmlFor="fileInput"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <SaveAltIcon />
                </label>
                <Typography
                  style={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Upload File Attachments
                </Typography>
                <Typography
                  style={{
                    color: "#666666",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Pdf,Word,Excel
                </Typography>
              </label>
            ) : (
              <Typography data-testId="attatchmentName" style={attatchment?.name?{}:{cursor:"pointer",color:"blue",textDecoration:"underline"}} onClick={handleDownload} component={"h3"} variant="h5">
                {attatchmentName}
              </Typography>
            )}
            {attatchment !== null && (
              <Box style={{display:"flex",alignItems:"center",gap:"10px"}}>
                   <Box
                    data-testid="removeAttatchment"
                    onClick={removeHandler}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2",
                      cursor: "pointer",
                    }}
                   >
                    <DeleteOutlineOutlinedIcon
                      style={{ color: "rgba(0,0,0,0.54)" }}
                    />
                    <Typography
                      style={{
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Delete Files
                    </Typography>
                  </Box>
              </Box>
             
            )}
          </Box>
          {error && (
            <Typography
              style={{ color: "red", fontSize: "16px" }}
              component="p"
              variant="h6"
            >
              {error}
            </Typography>
          )}
    </>
  );
};

export default SelectAttatchment;
