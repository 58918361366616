import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { MsalContext } from "@azure/msal-react";
import CreateRestApiMessage from "../../../components/src/util/CreateRestApiMessage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchResult: string;
  landingPageProjects: any[];
  newProjectApplications: any[];
  tagList: any[];
  checkedTagList:any[];
  tagNames:any[];
  toolTipModel:boolean;
  pagination:{
    per_page: number,
            current_page: number,
            next_page: null|number,
            prev_page: null|number,
            total_pages: number,
            total_count: number
  },
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
  static contextType = MsalContext;
  getNewProjectApplicationApiCallID: string = "";
  getDataBySearchApiCallID: string = "";
  getAllProjectsApiCallID: string = "";
  getTagListApiCallID: string ="";
  getFilteredTagListApiCallId :string ="";
  observer:any=null;
  containerRef:any=null;
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ]);
    
    this.subScribedMessages = [
    ];

    this.state = {
      landingPageProjects: [],
      newProjectApplications: [],
      searchResult: "",
      tagList: [],
      checkedTagList:[],
      tagNames:[],
      toolTipModel:false,
      pagination:{
        current_page:1,
        next_page:1,
        per_page:10,
        prev_page:null,
        total_count:0,
        total_pages:0,
      },
      loading:false,

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);    
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start  
     if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response?.errors?.length > 0 && response?.errors[0]?.token) {
        localStorage.removeItem("accessToken");
        this.navigateToLogin();
      }
      if (apiRequestId === this.getAllProjectsApiCallID) {
        this.handleGetAllProjectPayload(response);
      }
      if (apiRequestId === this.getNewProjectApplicationApiCallID) {        
        this.handleGetNewProjectApplicationsPayload(response);
      }
      if (apiRequestId === this.getDataBySearchApiCallID) {
        this.handleGetSearchResultPayload(response);
      }
      if (apiRequestId === this.getTagListApiCallID) {
        this.handleGetTagListPayload(response);
      }
      if (apiRequestId === this.getFilteredTagListApiCallId) {
        this.handleTagListByFilterPayload(response);
      }

    }
    // Customizable Area End
  }
   // Customizable Area Start
  handleGetAllProjectPayload = (response: any) => {
    this.setState((prev)=>{
      return{
        landingPageProjects:response?.data?.length>0?[...prev.landingPageProjects,...response?.data]:prev.landingPageProjects,
        loading:false,
        pagination:response?.meta?.pagination 
      }
    })

  }

  handleGetNewProjectApplicationsPayload = (response: any) => {
    this.setState((prev)=>{
      return{
        newProjectApplications:response?.data?.length>0?[...prev.newProjectApplications,...response?.data]:prev.newProjectApplications,
        loading:false,
        pagination:response?.meta?.pagination 
      }
    })

  }
  handleGetSearchResultPayload = (response: any) => {
    this.setState({ landingPageProjects: response?.data });
    this.setState({ newProjectApplications: response?.data });
  }
  handleGetTagListPayload=(response:any)=>{
    this.setState({tagList:response});
  }
  handleTagListByFilterPayload=(response:any)=>{
    this.setState({ landingPageProjects: response?.data });
    this.setState({ newProjectApplications: response?.data });
  }

  async componentDidMount() {
 const isAuthenticated = this.context?.accounts.length > 0;
    if (!isAuthenticated) {
      this.navigateToLogin();
      return;
    }

    this.getAllProjects();
    this.getNewProjectApplications();
    this.getTagList();
  }
 
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleOnSearch = (event: any) => {
    this.getDataBySearchKeyword(event);
  }

  getDataBySearchKeyword = (event: any) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getDataBySearchKeyword}?search=${event?.target?.value}&commit=Search`,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getDataBySearchApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOnCheckBoxClick=(data:any[])=>{
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    if(data?.length>0){
   
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getProjectByTagFilter}?tag_ids=[${data}]`,
      body: null,
      method: configJSON.getApiMethodType,
    });
  
    this.getFilteredTagListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }else{
    this.getAllProjects()
  }
  }

  getAllProjects = () => {
    const {pagination}=this.state;
    if(pagination?.next_page===null){
      return;
    }
    this.setState({loading:true});
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getAllProjects}?per=${pagination?.per_page}&page=${pagination?.next_page}`,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getAllProjectsApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNewProjectApplications = () => {
    const {pagination}=this.state;
    if(pagination?.next_page===null){
      return;
    }

    this.setState({loading:true})
    const hitUrl=`${configJSON.getNewProjectApplications}?per=${pagination?.per_page}&page=${pagination?.next_page}`

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getNewProjectApplicationApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTagList = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("accessToken"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getTagList,
      body: null,
      method: configJSON.getApiMethodType,
    });
    this.getTagListApiCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  checkedCheckBoxName=(tagNames:any[])=>{
    this.setState({tagNames:[...tagNames]})
  }
 
  navigateToLogin = () => {
    this.props.navigation.navigate("Login");
  };

  handleonClickToNewProject=()=>{
    this.props.navigation.navigate("CreateProject");
  }

  handleOnRedirectToLandingPage=()=>{
    const isAdmin =localStorage?.getItem("isAdmin")==="true"?true:false;
    if(!!isAdmin)
    {
      this.props.navigation.navigate("AdminLandingPage");
    }
    else{
      this.props.navigation.navigate("ApplicantLandingPage");
    }
  }

  showProjectDetail=(id:any)=>{
    this.props.navigation.navigate("ProjectDetails",{id});
    }
  // Customizable Area End
}
