import React, { useState,useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  newProjectIcon,
  activeNewProjectIcon,
  browseProjectIcon,
  activeBrowseProjectIcon,
  settingIcon,
  activeSettingIcon,
  Logoelia,
  avatar
} from "./assets";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);
const loginRequest = {
  scopes: ["User.Read"],
};
let allTagId: any = [];
let tagNames:any=[];
const SideBar = ({ taglist, checkedCheckBoxList, checkedCheckBoxName,clickOnLogo}: any) => {
  const { accounts ,instance} = useMsal();
  const classes = useStyles();
  const [image, setImage] = useState<any>()

  const [isOk, setResponse] = useState<any>()
  let endpoint = window.location.pathname;


  const getAllTagsById = (id: any,name:string) => {

    if (allTagId.includes(id)) {
      const tag = allTagId.filter((data: any) => {
        return data !== id;
      });
      const tagName = tagNames.filter((data:string)=>{
        return data !== name;
      })
      allTagId = tag;
      tagNames = tagName;
    }
    else {
      allTagId.push(id)
      tagNames.push(name)
    }
    checkedCheckBoxList(allTagId);
    checkedCheckBoxName(tagNames);
  }
  const handleLogout = (logoutType: string = "popup") => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/Login",
        mainWindowRedirectUri: "/Login",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/Login",
      });
    }
  };


const GetProfile = async () => {
  try{
    const tokenResponse=await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        })
      if(!tokenResponse?.accessToken){
        return
      }
    const headers = new Headers();
    const bearer = `Bearer ${tokenResponse?.accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Content-type", 'image/jpeg');
    await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
        method: "GET",
        headers: headers,
    })
    .then(response => {
        response.blob().then((data) => {
            const reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => {
            const base64data = reader.result;
            
            setImage(base64data)
            setResponse(response.ok)
            
            }
        })
    })
  }catch(err){
     
  }
   
} 
useEffect(() => { 
  GetProfile()
}, [])
const name=accounts[0]?.name;


  return (<Box sx={webStyle.sideBar}>   
        <Box onClick={clickOnLogo} style={{marginBottom:"24px"}}>
        <Link to={"/AdminLandingPage"} style={webStyle.linkItem}>
          <img src={Logoelia} style={webStyle.imgLogo as CSSProperties} />
        </Link>
        </Box>
        {(endpoint === "/AdminLandingPage" || endpoint === "/AdminBrowseProject" || endpoint === "/Setting") &&
        <>
        <List component="nav" aria-label="main mailbox folders" >
          <Link to={"/AdminLandingPage"} style={webStyle.linkItem}>
            <ListItem button style={endpoint === "/AdminLandingPage" ? webStyle.navItemActive : webStyle.navItem as CSSProperties}>
              <ListItemIcon style={endpoint === "/AdminLandingPage" ? webStyle.navIconActive : webStyle.navIcon as CSSProperties}>
                <img src={endpoint === "/AdminLandingPage" ? activeNewProjectIcon : newProjectIcon} style={webStyle.imgIcon} />
              </ListItemIcon>
              <ListItemText primary="New Project Application" />
            </ListItem>
          </Link>
          <Link to={"/AdminBrowseProject"} style={webStyle.linkItem}>
            <ListItem button style={endpoint === "/AdminBrowseProject" ? webStyle.navItemActive : webStyle.navItem as CSSProperties}>
              <ListItemIcon style={endpoint === "/AdminBrowseProject" ? webStyle.navIconActive : webStyle.navIcon as CSSProperties}>
                <img src={endpoint === "/AdminBrowseProject" ? activeBrowseProjectIcon : browseProjectIcon} style={webStyle.imgIcon} />
              </ListItemIcon>
              <ListItemText primary="Browse Project Portfolio" />
            </ListItem>
          </Link>
          <Link to={"/SettingsPage"} style={webStyle.linkItem}>
            <ListItem button style={webStyle.navItem}>
              <ListItemIcon style={webStyle.navIcon}>
                <img src={endpoint === "/Setting" ? activeSettingIcon : settingIcon} style={webStyle.imgIcon} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </Link>
        </List>
      </>
    }
    {
      (endpoint === "/AdminBrowseProject" || endpoint === "/ApplicantLandingPage") &&
      <Box sx={ endpoint==="/ApplicantLandingPage" ? webStyle.ApplicanttagsList: webStyle.tagsList}>
        <Typography variant="h4" style={webStyle.title as CSSProperties}>
          Tags
        </Typography>
        <Box className={classes.root}>
          <FormGroup style={endpoint==="/ApplicantLandingPage" ? webStyle.applicantListScroll as CSSProperties: webStyle.listScroll as CSSProperties}>
            <Box>
              {taglist?.data?.map((data: any) => {
                return (
                  <FormControlLabel style={webStyle.checkLabel as CSSProperties}
                    onClick={() => { getAllTagsById(data?.id, data?.attributes?.name) }}
                    control={<Checkbox name="gilad" style={webStyle.checksBox} />}
                    labelPlacement="start"
                    label={<Typography style={webStyle.listScrollTypography as CSSProperties} ><span style={{ ...webStyle.checkBoxColor, backgroundColor: `${data.attributes.color}` }}> </span>{data.attributes.name}</Typography>}
                  />)
              })}
            </Box>
          </FormGroup>
        </Box>
      </Box>
    }
    {(endpoint === "/AdminLandingPage" || endpoint === "/AdminBrowseProject") &&
      <Box>
        <List component="nav" aria-label="main mailbox folders" style={webStyle.navLogout as CSSProperties}>
          <ListItem button style={webStyle.navItemLog}>
            <Box sx={webStyle.flexBox}>
              <img src={isOk?image:avatar} style={webStyle.imgUser} />
              <ListItemText><b>{name}</b></ListItemText>
            </Box>
            <ListItemIcon style={webStyle.navIconLog}>
              <ExitToAppIcon onClick={() => handleLogout()}>
              </ExitToAppIcon>
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>
    }
  </Box>
  );
};

export default SideBar;
const webStyle = {
  sideBar: {
    width: "312px",
    position: "fixed",
    padding: "33px 16px 0",
    boxSizing: "border-box",
  },
  title: {
    fontWeight: "500",
    fontSize: "20px",
    color: "#171717",
  },
  imgLogo: {
    Width: "162px",
  },
    navItem: {
    color: "#999999",
    padding: "4px 15px",
    marginBottom: "4px",
    "&:hover": {
      backgroundColor: "#444",
    },
  },
  navItemActive: {
    padding: "4px 15px",
    backgroundColor: "rgba(228, 110, 42, 0.2)",
    color: "#E46E2A",
    borderRadius: "8px",
    marginBottom: "4px",
  },
  navIconActive: {
    minWidth: "40px",
    color: "#E46E2A",
  },
  navLogout: {
    position: 'fixed',
    maxWidth: '300px',
    bottom: 0,
    left: "0",
    right: "0",
    borderTop: "1px solid #E4E4E4",
  },
  navItemLog: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 16px 24px",
    background: "#fff",
  },
  navIcon: {
    minWidth: "40px",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    color: "#171717",
  },
  imgUser: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "12px",
  },
  imgIcon: {
    width: "20px",
    height: "20px",
  },
  navIconLog: {
    minWidth: "auto",
  },  
  tagsList: {
    borderTop: "1px solid #E4E4E4",
    paddingTop: "16px",
  },   
  ApplicanttagsList: {
    paddingTop: "16px",
  },
  listScroll: {
    maxHeight: 'calc(100vh - 390px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: '12px',
    width: '100%',
    scrollbarWidth: 'none',
  },
  applicantListScroll: {
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: '12px',
    width: '100%',
    scrollbarWidth: 'none',
  },
  listScrollTypography: {
    fontSize: "12px",
  },
  checksBox: {
    padding: "0",
    color: "#E1E4E6 !important",
  },
  checkLabel: {
    marginLeft: "0px",
    display: "flex",
    justifyContent: "space-between",
    background: "#F5F6F7",
    padding: "8px 12px",
    marginTop: "12px",
    borderRadius: "8px",
  },
  checkBoxColor: {
    borderRadius: "100%",
    width: "12px",
    height: "12px",
    display: "inline-block",
    marginRight: "8px",
  },
  linkItem: {
    textDecoration: "none",
    color: "inherit",
  },
};
// Customizable Area End
