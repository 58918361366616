import React from 'react'

import {
    Typography,
    Box,
  } from "@material-ui/core";
  
const Tooltop = () => {

  return (
    <Box style={{position:"relative"}}>
    <Box style={{padding:"10px", textAlign:"center", width:"158px", backgroundColor:"white", borderRadius:"4px", position:"absolute"}}>
  <Typography style={{fontSize:"12px", lineHeight: "14px", color:"#171717", fontWeight:400}}>Users Guidance Notes</Typography>
  <Typography style={{fontSize:"8px", lineHeight: "9.5px", paddingInline:"13px",paddingTop:"8px", color: "#999999", fontWeight:500}}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
 </Box>
  </Box>
  )
}
  
export default Tooltop