import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Select,
} from "@material-ui/core";
import { ITagType } from "../../../blocks/Settings5/src/settings.types";

interface Props {
  data?:any[]
  value?: string[];
  error?: string;
  onChange?: (e: any) => void;
  label: string;
  placeholder: string;
  type?: string;
  renderItem:(item:any)=>void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel_multiple: {
      color: "#666666",
      fontSize: "16px",
      fontWeight: 600,
    },
    errorText_multiple: {
      color: "red",
      fontSize: "16px",
      fontWeight: 500,
      marginTop: "5px",
    },
    dropdown__main_multiple: {
      fontSize: "14px",
      fontWeight: "bolder",
      width: "100%",
      background: "transparent",
      "&:hover": {
        borderBottom: "0px",
      },
      "&::after": {
        borderBottom: "0px !important",
      },
      "&::before": {
        borderBottom: "0px !important",
      },
      "&$selected": {
        borderBottom: "0px !important",
      },
       "&:focus": {
        background:"transparent"
      },
    },
  })
);
const CustomSelect = ({
  value,
  error,
  onChange,
  label,
  placeholder,
  type = "text",
  renderItem,
  data
}: Props) => {
  const styles = useStyles();
  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <Typography className={styles.inputLabel_multiple}>{label}</Typography>
      <Box sx={{ bgcolor: "white", borderRadius: "8px", padding: "15px 20px" }}>
         
        <Select 
            value={value}
            onChange={onChange}
            className={styles.dropdown__main_multiple}
            multiple
            renderValue={(selected)=>(
             <Box style={{display:"flex",flexWrap:"wrap",gap:"7px",}}>
                {
                    value?.map((item:string)=>{
                      const tag:ITagType=data?.filter((tg:ITagType)=>tg?.id===item)[0];
                      return(
                        (
                        <Box key={JSON.stringify(item)} sx={{bgcolor:"red",borderRadius:"15px",padding:"6px 15px"}}>
                            <Typography style={{color:"white"}}>{tag?.attributes?.name}</Typography>
                        </Box>
                        )
                      )
                    })
                }
             </Box>
            )}
            >
          {
            data?.map(item=>renderItem(item))
          }
        </Select>
      </Box>
      {error && <Typography className={styles.errorText_multiple}>{error}</Typography>}
    </Box>
  );
};

export default CustomSelect;
