import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      textTransform: "capitalize",
      borderRadius: "15px",
      boxShadow: "none",
      minWidth: "120px",
    },
    ashButton: {
      borderRadius: "15px",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: 400,
      color: "black",
      minWidth: "120px",
      "&:hover": {
        boxShadow: "none",
      },
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      justifyContent: "end",
    },
  })
);

interface Props {
  edit: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  actionLoading?: boolean;
  actionHandler?:any;
  businessUnit?:string;
}

export default function BusinessUnitModal({
  open,
  setOpen,
  edit,
  actionLoading,
  actionHandler,
  businessUnit
}: Props) {
  const styles = useStyles();
  const [businessUnitName, setBusinessUnitName] = React.useState<string>(edit?businessUnit as string:"");
  const [errors,setErrors]=React.useState<any>({})

  const handleSubmit=()=>{
     if(!businessUnitName){
       setErrors({
         businessUnitName:"Please Enter Business Unit Name"
       });
     }else{
       const body = {
         business_unit: {
           name: businessUnitName,
         },
       };
       actionHandler(body)
       setErrors({})
     }
  }


  const getButtonText = () => {
    if (edit) {
      return "Update";
    } else {
      return "Save";
    }
  };

  return (
    <Modal
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper
        style={{
          width: "680px",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <Typography data-testid="businessmodal-title" variant="h5" style={{ fontWeight: 600, fontSize: "22px" }}>
          {edit ? "Update Business Unit" : "Create Business Unit"}
        </Typography>

        <Box sx={{ py: 3 }}>
          <TextField
            data-testid="businessmodal-departmentet"
            fullWidth
            label="Department"
            variant="outlined"
            value={businessUnitName}
            onChange={(e) => setBusinessUnitName(e.target.value)}
            style={{ marginBottom: "10px" }}
            error={errors?.businessUnitName}
            helperText={errors?.businessUnitName}
          />
        </Box>

        <Box className={styles.buttonContainer}>
          <Button
            data-testid="businessmodal-cancelbtn"
            onClick={() => setOpen(false)}
            variant="contained"
            className={styles.ashButton}
          >
            Cancel
          </Button>
          <Button
            data-testid="businessmodal-savebtn"
            onClick={handleSubmit}
            disabled={actionLoading}
            variant="contained"
            color="primary"
            className={styles.submitButton}
          >
            {actionLoading ? <CircularProgress size={24} /> : getButtonText()}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
