import React from "react";

import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Card,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectImage: {
      height: "140px",
      width: "100%",
      objectFit: "cover",
    },
    projectOwnerText: {
      color: "#314346",
      fontWeight: 400,
      fontSize: "12px",
      marginBottom: "5px",
    },
    projectTitle: {
      color: "#171717",
      fontSize: "15px",
      fontWeight: "bolder",
      marginBottom: "5px",
      
    },
    projectPrice: {
      color: "#E46E2A",
      fontSize: "20px",
      fontWeight: 700,
      marginBottom: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "120px",

    },
    projectYear: {
      color: "#999999",
      fontSize: "18px",
      fontWeight: 700,
    },
    projectDescription: {
      color: "#999999",
      fontSize: "16px",
      fontWeight: 500,
      marginBottom: "5px",
  
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre-line",
        display: "-webkit-box",
        "-webkit-line-clamp": 4,
        "-webkit-box-orient": "vertical",
        /* height: calc(100% - 0px), */
        position: "relative",
      }
  }),

);

interface Props {
  projectData: any;
  navigation: any; 
  getProjectDetail: (projectId: any) => void;
}
const projectImage =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIB0jQ8BfaxJS75ytpE4_G628jcDixcjJH6J-ISoxgNQ&s";
const ProjectItem = ( {projectData, navigation, getProjectDetail}: Props ) => {
  const data =projectData
  const styles = useStyles();
  const  projectId = parseInt(data?.id)

  const fetchProjectData = (id: number) => {
    navigation.navigate('ProjectDetails', {id})
    getProjectDetail(id)
  }

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <Card elevation={0} 
        style={{ borderRadius: "10px", position: "relative", maxHeight: 400, minHeight: 400, height: "100%" }}>
        <img
         
          onError={(e:React.SyntheticEvent<HTMLImageElement, Event>)=>{
            e.currentTarget.onerror=null;
            e.currentTarget.src= projectImage;
          }}
          src={ data.attributes.cover_image? data.attributes.cover_image : projectImage}
          alt="Project Image"
          className={styles.projectImage}
        />
        <Box sx={{ px: 4, py: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                className={styles.projectOwnerText}
                component="p"
                variant="h6"
              >
                {data?.attributes?.idea_owner_name}
              </Typography>
              
              <Typography
                className={styles.projectPrice}
                component="h1"
                variant="h1"
              >
                {data?.attributes?.idea_title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={styles.projectOwnerText}
                component="p"
                variant="h6"
              >
                {data?.attributes?.submitted_date}
              </Typography>
              <Typography
                className={styles.projectYear}
                component="h1"
                variant="h1"
              >
                 {data?.attributes?.submitted_year}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            className={styles.projectDescription}
            component="p"
            variant="h6"
          >
           {data?.attributes?.idea_description}
          </Typography>
          <div>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => fetchProjectData(projectId)}
            style={{
              textTransform: "capitalize",
              boxShadow: "none",
              borderRadius: "8px",
              bottom: 15,
              position: "absolute",
              width:"100%",
              margin:"15px auto 0",
              maxWidth: "185px",
              minWidth: "185px",
              left: 0,
              right: 0,
            }}
          >
            Open
          </Button>
          </div>
        </Box>
      </Card>
    </Grid>
  );
};

export default ProjectItem;
