//@ts-nocheck
import React, { CSSProperties } from "react";
import ProjectDetailsController, {
  Props,
} from "./ProjectDetailsController.web";

import { Box, Typography, Grid, IconButton, FormControlLabel, FormGroup, Checkbox, Button } from "@material-ui/core";
import CustomTextInput from "../../../components/src/util/CustomTextInput.web";
import CustomTextArea from "../../../components/src/util/CustomTextArea.web";
import ProjectItem from "../../../components/src/util/ProjectItem.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomLayout from "../../../components/src/util/CustomLayout.web";
import { pdfImg } from './assets';

export default class ProjectDetails extends ProjectDetailsController {
  constructor(props: Props) {
    super(props);
  }



  render() {
    const { submittedDate,
      businessUnit,
      ideaOwnerName,
      description,
      title,
      tags,
      similarProjects, fileName, fileSize, file } = this.state
    const isAdmin = this.isAdmin;
    const middleGrid = isAdmin ? 9 : 6;

    return (
      <CustomLayout backButton={false} title={title ? title : "Project Title"} >
        <Box sx={{ marginTop: "130px" }} />
        <Grid
          style={{
            width: "100%",
            padding: isAdmin ? "0px 30px" : "0px",
            boxSizing: "border-box",
          }}
          container
          spacing={4}
        >
          {isAdmin || (
            <Grid
              data-testid="user-leftsidebar"
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
            >
              <IconButton
                style={{ background: "transparent", marginLeft: "10px" }}
                onClick={() => this.props.navigation.goBack()}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "7px",
                  }}

                >
                  <ArrowBackIcon  />
                  <Typography>Back</Typography>
                </Box>
              </IconButton>
            </Grid>
          )}
          {isAdmin && (
            <div
              style={{
                textAlign: 'end',
                background: "rgb(242, 242, 242) ",
                width: '100%'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  borderRadius: "8px",
                  width: 150,
                }}
                onClick={this.navigateToEditProject}
              >
                Edit
              </Button>
            </div>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={middleGrid}
            lg={middleGrid}
            xl={middleGrid}
            ref={this.ref}

            style={{
              height: "calc(150vh - 114px)",
              overflow: "scroll",
            }}
            className="custom_scrollbar"

          >
            <Box sx={{ width: "100%" }}>
              <CustomTextInput
                label="Date Submitted"
                placeholder="Date Submitted"
                value={submittedDate}
                readOnly={true}
              />
              <br />
              <CustomTextInput
                label="Buisness Unit"
                placeholder="Buisness Unit"
                value={businessUnit}
                readOnly={true}
              />
              <br />
              <CustomTextInput
                label="Idea Owner Name"
                placeholder="Idea Owner Name"
                value={ideaOwnerName}
                readOnly={true}
              />
              <br />
              <CustomTextArea
                label="Idea Description"
                placeholder="Please tell us about your idea.."
                rows={4}
                value={description}
                readOnly={true}
              />
              <br />
              <div style={webStyle.devider} />
              <Typography
                component="h1"
                variant="h3"
                style={webStyle.headerTitleStyle}
              >
                Similar Projects
              </Typography>
              <Grid container spacing={4}
              // style={{ height: '70%', overflowX: "scroll" }}
              >
                {similarProjects.map((item, index) => (
                  <ProjectItem
                    key={index}
                    projectData={item}
                    navigation={this.props.navigation}
                    getProjectDetail={this.getProjectDetail}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            {isAdmin ? (
              <>
                <div style={{ paddingBottom: 20 }}>
                  <Typography
                    component="h1"
                    variant="h3"
                    style={webStyle.attachmentHeading}
                  >
                    File Attachments
                  </Typography>
                  <Box
                    style={{
                      marginTop: "15px",
                      background: "white",
                      borderRadius: "15px",
                      padding: "10px",
                      marginRight: "20px",
                      border: "2px dashed #E4E4E4",
                    }}
                  >
                    <Box
                      style={{
                        borderRadius: "8px",
                        padding: "8px 12px",
                        alignItems: "center",
                        gap: "10px",
                        border: "2px dashed #E4E4E4",
                        textAlign: "center",
                        height: 280,
                      }}
                    >
                      <a href={file} download={fileName} target='blank'>
                        <img style={{
                          height: "148px",
                          width: "121px",
                          top: "52px",
                          borderRadius: 0,
                          position: 'relative',
                          marginTop: 'auto',
                        }}
                          src={pdfImg} alt="pdf"
                        />
                      </a>
                    </Box>
                    <Grid container style={{ paddingTop: 15, paddingBottom: 15 }}>
                      <Grid item xs={8}>
                        <Typography variant="h5" style={webStyle.pdfName}>
                          {fileName}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5" style={{
                          fontSize: 18,
                          color: "#171717",
                          float: "right",
                        }}>
                          {(Math.round(fileSize * 100) / 100).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </div>

                <div>
                  <Typography
                    component="h1"
                    variant="h3"
                    style={webStyle.headerTitleStyle}
                  >
                    Tags
                  </Typography>
                  <Box style={{
                    marginTop: "15px",
                    background: "white",
                    borderRadius: "15px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    marginRight: "20px",
                  }}>
                    <FormGroup style={webStyle.listCheckBox as CSSProperties}>
                      <Box>
                        {tags.map((item: any) => (
                          <FormControlLabel style={webStyle.checkBoxLabel as CSSProperties}
                            labelPlacement="start"
                            control={<Checkbox name="jason" style={webStyle.checkBox} checked />}
                            label={<Typography  component="h2" variant="h5"  style={webStyle.listScrollText as CSSProperties}>
                              <span style={{
                                ...webStyle.checkBoxColors, backgroundColor: item?.color
                              }}> </span>
                              {item?.name}
                            </Typography>}
                          />
                        ))}
                      </Box>
                    </FormGroup>
                  </Box>
                </div>
              </>
            ) : (
              <div>
                <Typography
                  component="h1"
                  variant="h3"
                  style={webStyle.headerTitleStyle}
                >
                  Tags
                </Typography>
                <Box
                  style={{
                    marginTop: "15px",
                    background: "white",
                    borderRadius: "15px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    marginRight: "20px",
                  }}
                >
                  {tags.map((item: any, index: any) => (
                    <Box
                      key={index}
                      style={{
                        background: "#F5F6F7",
                        borderRadius: "8px",
                        padding: "15px 15px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "50%",
                          background: item?.color,
                        }}
                      />
                      <Typography component="h2" variant="h5" style={webStyle.listScrollText as CSSProperties}>
                        {item?.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </div>
            )}
          </Grid>
        </Grid>
      </CustomLayout>
    );
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    bgcolor: "#F2F2F2",
    height: "100vh",
    overflow: "hidden",
    paddingBottom: "50px",
  },
  logo: {
    width: "162px",
    height: "40px",
    objectFit: "cover",
  },
  inputLabel: {
    color: "#666666",
    fontSize: "16px",
    fontWeight: 500,
  },

  devider: {
    height: "2px",
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
    background: "#E4E4E4",
  },
  headerTitleStyle: {
    fontSize: "36px",
    fontWeight: 600,
    color: "black",
    marginBottom: "15px",
  },
  attachmentHeading: {
    fontSize: "12px",
    color: "#66676A",
  },
  pdfImage: {
    height: "148px",
    width: "121px",
    top: "52px",
    borderRadius: 0,
    position: 'relative',
    marginTop: 'auto',
  },
  pdfName: {
    fontSize: 18,
    color: "#171717",
  },
  pdfSize: {
    fontSize: 18,
    color: "#171717",
    float: "right",
  },
  checkBox: {
    padding: '0',
    color: '#E46E2A !important',
  },
  checkBoxLabel: {
    marginLeft: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#F5F6F7',
    padding: '12px 15px',
    marginTop: '12px',
    borderRadius: '8px',
  },
  checkBoxColors: {
    backgroundColor: "#000000",
    borderRadius: "100%",
    width: "12px",
    height: "12px",
    display: 'inline-block',
    marginRight: '8px',
  },
  listScrollText: {
    fontSize: "14px",
  },
  listCheckBox: {
    height: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: '12px',
    width: '100%',
    scrollbarWidth: 'none',
  },
};
